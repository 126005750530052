import { createApi } from "@reduxjs/toolkit/query/react";
import { apiRoot, baseQueryWithRetryAndReAuth } from "../../global";

export const api = createApi({
  reducerPath: "runTheDayApi",
  baseQuery: baseQueryWithRetryAndReAuth,
  tagTypes: ["Login", "UserManagement", "Race", "RaceDetails", "Reports"],
  endpoints: (builder) => ({
    rtdLogging: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Login"],
    }),

    verifyOtp: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Login"],
    }),

    changePassword: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Login"],
    }),

    resendOtp: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Login"],
    }),

    logOut: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
      }),
      invalidatesTags: ["Login"],
    }),

    getSubAdminList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}get-subadmins?limit=${data.limit}&offset=${data.offset}&searchtext=${data.searchtext}&sort=${data.sort}`,
        method: "GET",
      }),
      providesTags: ["UserManagement"],
    }),

    getRunnerList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}get-users/runners?limit=${data.limit}&offset=${data.offset}&searchtext=${data.searchtext}&sort=${data.sort}`,
        method: "GET",
      }),
      providesTags: ["UserManagement"],
    }),

    getRaceDirectorList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}get-users/race-directors?limit=${data.limit}&offset=${data.offset}&searchtext=${data.searchtext}&sort=${data.sort}`,
        method: "GET",
      }),
      providesTags: ["UserManagement"],
    }),

    reGeneratePassword: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["UserManagement"],
    }),

    changeUserRole: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["UserManagement"],
    }),

    addSubAdmin: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["UserManagement"],
    }),

    updateSubAdmin: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "PATCH",
        body: data.body,
      }),
      invalidatesTags: ["UserManagement"],
    }),

    updateStatus: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "PATCH",
        body: data.body,
      }),
      invalidatesTags: ["UserManagement"],
    }),

    getRaceList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}races?status=${data.status}&limit=${data.limit}&offset=${data.offset}&completionstatus=${data.completionstatus}&searchtext=${data.searchtext}&sort=${data.sort}`,
        method: "GET",
      }),
      providesTags: ["Race"],
    }),

    getRaceDetails: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}race/${data}`,
        method: "GET",
      }),
      providesTags: ["Race"],
    }),

    getFeaturedRace: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}race-featured-requests?limit=${data.limit}&offset=${data.offset}`,
        method: "GET",
        body: data.body,
      }),
      providesTags: ["Race"],
    }),

    updateFeaturedRace: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "PATCH",
        body: data.body,
      }),
      invalidatesTags: ["Race"],
    }),

    getRaceDetailsSponsors: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}race/${data}/sponsors`,
        method: "GET",
      }),
      providesTags: ["RaceDetails"],
    }),

    getRaceDetailsCoupons: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}race/${data.id}/coupons?limit=${data.limit}&searchtext=${
          data.searchtext || ""
        }&offset=${data.offset}`,
        method: "GET",
      }),
      providesTags: ["RaceDetails"],
    }),

    getRaceDetailsTeams: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}race/${data.id}/teams?searchtext=${data.searchtext}&limit=${data.limit}&offset=${data.offset}`,
        method: "GET",
      }),
      providesTags: ["RaceDetails"],
    }),

    getTeamDetails: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}team-details/${data}`,
        method: "GET",
      }),
      providesTags: [],
    }),

    getRgistrationTypeList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}get-registration-types/${data.id}`,
        method: "GET",
      }),
      providesTags: [],
    }),

    getEventsTypeList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}events-by-registration-type/${data.id}`,
        method: "GET",
      }),
      providesTags: [],
    }),

    getTeamsTypeList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}teams-by-race-id/${data.id}`,
        method: "GET",
      }),
      providesTags: [],
    }),

    getTShirtList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}get-tshirts-dropdown?race_id=${data.id}`,
        method: "GET",
      }),
      providesTags: [],
    }),

    getDefaultValueCSV: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}get-defualt-values-for-csv?race_id=${data.id}`,
        method: "GET",
      }),
      providesTags: [],
    }),

    getRaceDetailsDonations: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}race/${data.id}/donations?searchtext=${data.searchtext}&limit=${data.limit}&offset=${data.offset}`,
        method: "GET",
      }),
      providesTags: ["RaceDetails"],
    }),

    getLiveResultStatus: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}trigger-live-result?live_result_status=${data.status}&race_id=${data.id}`,
        method: "GET",
      }),
      invalidatesTags: ["RaceDetails"],
    }),

    getParticipantDetails: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}participant/${data}/detail`,
        method: "GET",
      }),
    }),

    updateRaceDetailStatus: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "PATCH",
        body: data.body,
      }),
      invalidatesTags: ["RaceDetails"],
    }),

    getParticipantsListCsvDownload: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      providesTags: ["RaceDetails"],
    }),

    getParticipantsListCsv: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      providesTags: ["RaceDetails"],
    }),

    updateParticipantsList: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["RaceDetails"],
    }),

    addManuallyParticipants: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["RaceDetails"],
    }),

    addManuallyDonations: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["RaceDetails"],
    }),

    getRaceCategoriesList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}race/${data.id}/categories`,
        method: "GET",
      }),
      providesTags: ["RaceDetails"],
    }),

    getMasterGenderList: builder.query<any, any>({
      query: () => ({
        url: `${apiRoot}get-master-data`,
        method: "GET",
      }),
    }),

    addCategories: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["RaceDetails"],
    }),

    updateRaceCategories: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "PATCH",
        body: data.body,
      }),
      invalidatesTags: ["RaceDetails"],
    }),

    updateTeamGoal: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "PATCH",
        body: data.body,
      }),
      invalidatesTags: ["RaceDetails"],
    }),

    deleteRaceCategories: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "DELETE",
        body: data.body,
      }),
      invalidatesTags: ["RaceDetails"],
    }),

    getRegisterReportList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}reports/${data.id}/registration?search-text=${
          data.searchText || ""
        }&limit=${data.limits}&offset=${data.offsets}&sort=${data.sort}`,
        body: data.body,
        method: "GET",
      }),
      providesTags: ["Reports"],
    }),

    getDonationReportList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}reports/${data.id}/donation?search-text=${data.searchText || ""}&limit=${
          data.limits
        }&offset=${data.offsets}&sort=${data.sort}`,
        body: data.body,
        method: "GET",
      }),
      providesTags: ["Reports"],
    }),

    getVolunteerReportList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}reports/${data.id}/volunteer?search-text=${data.searchText || ""}&limits=${
          data.limit
        }&offset=${data.offsets}&sort=${data.sort}`,
        body: data.body,
        method: "GET",
      }),
      providesTags: ["Reports"],
    }),

    getTshirtsReportList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}reports/${data.id}/tshirt?search-text=${data.searchText || ""}&limit=${
          data.limits
        }&offset=${data.offsets}&sort=${data.sort}`,
        body: data.body,
        method: "GET",
      }),
      providesTags: ["Reports"],
    }),

    getImpersonateToken: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
    }),

    getRefreshToken: builder.query<any, any>({
      query: () => ({
        url: `${apiRoot}get-new-accesstoken`,
        method: "GET",
      }),
      providesTags: ["Login"],
    }),

    getFeaturedRaceList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}race-featured-requests?limit=${data.limit}&offset=${data.offset}&type=${data.type}`,
        method: "GET",
        body: data.body,
      }),
      providesTags: ["Race"],
    }),

    getCloneRace: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}race/${data.id}/clone-race?title=${data.title}`,
        method: "GET",
      }),
      invalidatesTags: ["Race"],
    }),

    getDeleteRace: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}delete-race/${data}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Race"],
    }),

    getDeleteParticipant: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}participant/${data}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RaceDetails"],
    }),

    getCompleteManualReset: builder.mutation<any, any>({
      query: (id: string) => ({
        url: `${apiRoot}race/${id}/complete-reset`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useRtdLoggingMutation,
  useVerifyOtpMutation,
  useChangePasswordMutation,
  useGetSubAdminListQuery,
  useGetRunnerListQuery,
  useGetRaceDirectorListQuery,
  useReGeneratePasswordMutation,
  useChangeUserRoleMutation,
  useAddSubAdminMutation,
  useUpdateSubAdminMutation,
  useUpdateStatusMutation,
  useResendOtpMutation,
  useLogOutMutation,
  useGetRaceListQuery,
  useGetFeaturedRaceQuery,
  useUpdateFeaturedRaceMutation,
  useGetRaceDetailsQuery,
  useGetRaceDetailsSponsorsQuery,
  useGetRaceDetailsCouponsQuery,
  useGetRaceDetailsTeamsQuery,
  useGetTeamDetailsQuery,
  useGetRaceDetailsDonationsQuery,
  useUpdateRaceDetailStatusMutation,
  useGetLiveResultStatusMutation,
  useGetParticipantDetailsMutation,
  useGetParticipantsListCsvDownloadQuery,
  useUpdateParticipantsListMutation,
  useAddManuallyParticipantsMutation,
  useAddManuallyDonationsMutation,
  useGetParticipantsListCsvQuery,
  useGetRaceCategoriesListQuery,
  useGetRgistrationTypeListQuery,
  useGetEventsTypeListQuery,
  useGetTeamsTypeListQuery,
  useGetTShirtListQuery,
  useGetDefaultValueCSVQuery,
  useGetMasterGenderListQuery,
  useAddCategoriesMutation,
  useUpdateRaceCategoriesMutation,
  useUpdateTeamGoalMutation,
  useDeleteRaceCategoriesMutation,
  useGetRegisterReportListQuery,
  useGetDonationReportListQuery,
  useGetVolunteerReportListQuery,
  useGetTshirtsReportListQuery,
  useGetImpersonateTokenMutation,
  useGetRefreshTokenQuery,
  useGetFeaturedRaceListQuery,
  useGetCloneRaceMutation,
  useGetDeleteRaceMutation,
  useGetDeleteParticipantMutation,
  useGetCompleteManualResetMutation,
} = api;
